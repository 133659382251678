<template>
  <div>
    <el-row>
      <el-col :span="12">
        <ul class="top-link-left">
          <li class="back-index">
            <router-link to="/type">返回首页</router-link>
          </li>
          <li class="link-other">
            <router-link to="/bigdata">综合页</router-link>
          </li>
          <li class="link-other">
            <router-link to="/data/monitor">在翼监控</router-link>
          </li>
        </ul>
      </el-col>
      <el-col :span="12">
        <ul class="top-link-right">
          <li class="link-other">
            <router-link to="/data/actual">历史数据</router-link>
          </li>
          <li class="link-other">
            <router-link to="/data/zongzhi">综治数据</router-link>
          </li>
          <li class="link-other">
            <router-link to="/data/shequ">工作</router-link>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {}
};
</script>

<style scoped>
ul {
  list-style: none;
}
.top-link {
  width: 100%;
}
.top-link-left {
  color: rgba(238, 233, 233, 0.966);
  text-align: center;
  text-align: left;
  padding-left: 10%;
  height: 40px;
  margin: 1.2% 0;
}
.top-link-left li {
  float: left;
  margin-right: 2%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.top-link-right {
  float: right;
  color: #fff;
  text-align: center;
  height: 40px;
  text-align: right;
  margin: 1.2% 0;
}
.top-link-right li {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  margin-right: 20px;
}
.back-index {
  background: url("../../assets/images/data/back_index_default.png") no-repeat;
  background-size: 100% 100%;
  width: 100px !important;
  font-size: 14px;
}
.top-link-left a {
  color: rgba(197, 196, 206, 0.664);
}
.top-link-right a {
  color: rgba(197, 196, 206, 0.664);
}
.back-index:hover {
  background: url("../../assets/images/data/back_hover.png") no-repeat;
  background-size: 100% 100%;
}
.link-other {
  background: url("../../assets/images/data/link_other_default.png") no-repeat;
  background-size: 100% 100%;
  width: 130px;
}
.link-other:hover {
  background: url("../../assets/images/data/link_other_hover.png") no-repeat;
  background-size: 100% 100%;
}
</style>
