<template>
  <el-container style="height: 667px; border: 1px solid #eee">
    <el-header style="text-align: center; font-size: 16px; height: 50px">
      <span>EasyTester-Serial</span>
    </el-header>

    <el-main
      class="infinite-list"
      style="white-space:pre-line;word-break:break-all;overflow:auto;font-size: 16px"
    >
      {{ r_neirong }}
    </el-main>

    <el-footer style="height: 100px">
      <el-input
        style="width: 260px"
        v-model="s_neirong"
        placeholder="请输入测试指令"
      />
      <el-button type="primary" icon="el-icon-share" @click="send_port"
        >发送</el-button
      >
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          速率<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="9600">9600</el-dropdown-item>
          <el-dropdown-item command="115200">115200</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" icon="el-icon-message" @click="connect_port"
        >连接</el-button
      >
      <el-button type="primary" icon="el-icon-share" @click="stop_port"
        >停止</el-button
      >
      <el-button type="primary" icon="el-icon-delete" @click="clear_content"
        >清屏</el-button
      >
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      r_neirong: "",
      s_neirong: "",
      baudRate: 9600,
      port: {},
      writer: {},
      reader: {},
      keepReading: false
    };
  },
  mounted() {
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      this.$message({
        message: "此浏览器支持，可以继续",
        type: "success"
      });
    } else {
      this.$message({
        message: "此浏览器不支持，请更换",
        type: "warning"
      });
    }
  },
  methods: {
    handleCommand(command) {
      this.baudRate = command;
    },
    async connect_port() {
      // Filter on devices with the Arduino Uno USB Vendor/Product IDs.
      const filters = [
        //{ usbVendorId: 0x0483, usbProductId: 0x5740 },
        // { usbVendorId: 0x2341, usbProductId: 0x0001 }
      ];
      let serial_setting = {
        baudRate: this.baudRate,
        dataBits: 8,
        stopBits: 1,
        parity: "none",
        bufferSize: 1500000,
        flowControl: "none"
      };
      // console.log(serial_setting);
      this.port = await navigator.serial.requestPort({ filters });
      // Wait for the serial port to open.
      await this.port.open(serial_setting);
      this.$message({
        message: "串口打开成功！",
        type: "success"
      });
      this.keepReading = true;
      this.read();
    },
    async send_port() {
      this.writer = this.port.writable.getWriter();
      this.keepReading = false;

      var arr = [];
      for (var i = 0, j = this.s_neirong.length; i < j; ++i) {
        arr.push(this.s_neirong.charCodeAt(i));
      }
      const send_data = new Uint8Array(arr); // hello
      await this.writer.write(send_data);

      // Allow the serial port to be closed later.
      this.writer.releaseLock();
      this.keepReading = true;
      this.read();
    },
    async read() {
      this.reader = this.port.readable.getReader();
      let buffer = "";
      this.keepReading = true;
      while (this.port.readable && this.keepReading) {
        try {
          while (this.keepReading) {
            const { value, done } = await this.reader.read();
            if (done) {
              // Allow the serial port to be closed later.
              this.reader.releaseLock();
              console.log("Stop reader.");
              break;
            }
            if (value) {
              buffer = "";
              for (let code of value) {
                buffer += String.fromCharCode(code);
              }
              this.r_neirong += buffer;
              // console.log(this.r_neirong);
              // console.log("++++++++++++++++++++");
            }
          }
        } catch (error) {
          // Handle non-fatal read error.
          console.error(error);
        } finally {
          console.log(this.port.readable, keepReading);
        }
      }
    },
    async stop_port() {
      this.keepReading = false;
      if (this.reader) {
        await this.reader.cancel();
      }
      await this.port.close();
      this.$message({
        message: "串口已关闭！",
        type: "warning"
      });
    },
    clear_content() {
      this.r_neirong = "";
    }
  }
};
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  line-height: 50px;
}
.el-footer {
  background-color: #b3c0d1;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
