<template>
  <div
    class="echart"
    id="echartline"
    :style="{ width: '100%', height: '92%', right: '2%' }"
  ></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  methods: {
    initChart(name, xData, yData) {
      let getchart = echarts.init(
        document.getElementById("echartline"),
        "dark"
      );
      getchart.clear();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          position: function(pos, params, el, elRect, size) {
            var obj = { top: 10 };
            obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
          extraCssText: "width: 170px, height: 60px"
        },
        axisPointer: {
          link: { xAxisIndex: "all" },
          label: {
            backgroundColor: "#777"
          }
        },
        // title: {
        //   text: "扫频VSWR"
        // },
        // tooltip: {
        //   trigger: "axis",
        //   show: false
        // },
        legend: {
          data: name
        },
        grid: {
          //调整图表上下左右位置
          //top:'10%',
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: "value"
        },
        series: yData
      };

      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
};
</script>
