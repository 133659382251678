<template>
  <div class="content" id="content" ref="homePage">
    <h2 class="title-h2">在翼天线监控数据</h2>
    <div class="top-link">
      <DataRouter></DataRouter>
    </div>
    <el-row class="main-con main-content">
      <el-col class="main-content" :span="4">
        <div class="chart-common chart-2">
          <h5>天线选择</h5>
          <el-form ref="form" :model="form">
            <el-form-item label="飞机号" prop="register_no" label-width="34%">
              <el-input
                v-model="form.register_no"
                placeholder="B-XXXX"
                @change="feijibian"
              ></el-input>
            </el-form-item>
            <el-form-item label="选择系统" label-width="34%">
              <el-cascader
                v-model="form.select_s_u"
                :options="form.system_unit"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="选择日期" label-width="34%">
              <el-select
                v-model="select_date"
                size="mini"
                placeholder="请选择"
                @change="selectDate"
              >
                <el-option
                  v-for="item in history_date"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col class="main-content" :span="11">
        <div class="chart-common chart-2">
          <h5>VSWR</h5>
          <ChartLine class="line_one" ref="chart_line_one" />
        </div>
      </el-col>
      <el-col class="main-content" :span="8">
        <div class="chart-common chart-5">
          <h5>TSLT TSR TSN</h5>
          <!-- <PeopleBar
            :chartData="chartData3"
            :chartYdata="chartYdata3"
            :yName="yName3"
            :xName="xName3"
            :colorZero="colorZero3"
            :colorHalf="colorHalf3"
            :colorAll="colorAll3"
          /> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DataRouter from "../../components/Data/DataRouter";
import { getMultidata } from "network/showdata.js";
import ChartLine from "../../components/content/echartLine.vue";
export default {
  name: "Monitor",
  data() {
    return {
      screenHeight: document.documentElement.clientHeight, //屏幕高度
      history_date: [],
      select_date: "",
      result: [],
      form: {
        register_no: "",
        select_s_u: [],
        system_unit: [
          {
            label: "TCAS",
            value: "TCAS",
            children: [
              { label: "UPP", value: "UPP" },
              { label: "BOT", value: "BOT" }
            ]
          },
          {
            label: "VHF",
            value: "VHF",
            children: [
              { label: "NO.1", value: "NO.1" },
              { label: "NO.2", value: "NO.2" },
              { label: "NO.3", value: "NO.3" }
            ]
          },
          {
            label: "RA",
            value: "RA",
            children: [
              { label: "NO.1-R", value: "NO.1-R" },
              { label: "NO.1-T", value: "NO.1-T" },
              { label: "NO.2-R", value: "NO.2-R" },
              { label: "NO.2-T", value: "NO.2-T" }
            ]
          },
          {
            label: "ATC",
            value: "ATC",
            children: [
              { label: "NO.1", value: "NO.1" },
              { label: "NO.2", value: "NO.2" }
            ]
          }
        ]
      },
      name: null,
      xData: [],
      yData: [],
      name: null,
      xData: [],
      yData: []
    };
  },
  components: {
    DataRouter,
    ChartLine
  },
  mounted() {
    var _this = this;
    //页面加载时赋值id全屏高度
    var oIframe = document.getElementById("content");
    oIframe.style.height = document.documentElement.clientHeight + "px";
    // 加载时echarts的高度
    window.onresize = function() {
      // 定义窗口大小变更通知事件
      _this.screenHeight = document.documentElement.clientHeight; //窗口高度
    };
    //echart操作
    // const vm = this;
    // vm.$nextTick(() => {});
    this.$refs.chart_line_one.initChart(this.name, this.xData, this.yData);
  },
  watch: {
    screenHeight: function(val) {
      //监听屏幕高度变化
      var oIframe = document.getElementById("content");
      oIframe.style.height = Number(val) + "px"; //'120'是页面布局调整，可去除
    }
  },
  created() {},
  methods: {
    feijibian() {
      this.history_date = [];
      this.select_date = "";
      if (this.form.select_s_u.length != 0) {
        this.handleChange();
      }
    },
    handleChange() {
      //console.log(this.form.register_no,this.form.select_s_u);
      this.history_date = [];
      this.select_date = "";
      getMultidata(
        this.form.register_no,
        this.form.select_s_u[0],
        this.form.select_s_u[1]
      ).then(res => {
        this.result = res;
        //console.log(this.result);
        if (this.result.length == 0) {
          this.$message.warning({
            message: "无查询数据！",
            type: "info"
          });
        } else {
          let date = [];
          for (let testJ of this.result) {
            //处理日期时间数据
            let str_date = testJ.DateTime.slice(0, 10);
            date.push(str_date);
          }
          this.history_date = date.filter(
            (val, ind, arr) => arr.indexOf(val) === ind
          );
          //console.log(this.history_date[0]);
          //this.selected_date_show(this.history_date[0]);
        }
      });
    },
    selectDate() {
      //每次选择日期，都将原来的数据清空
      (this.name = ["Reference"]), (this.xData = []), (this.yData = []);
      //发进行数据处理
      for (let test_data of this.result) {
        if (test_data.DateTime.search(this.select_date) != -1) {
          console.log("1");
          //处理文件名，得到曲线name
          let fullname = test_data.Data;
          let test_name = fullname.substr(
            fullname.search(this.form.select_s_u[0]),
            11
          );
          this.name.push(test_name);
          //处理频率和vswr
          let freq_vswr = test_data.fq_vswr.split("@");
          let vswr = [];
          let freq = [];
          for (let fr_vs of freq_vswr) {
            let f_v = fr_vs.split("**");
            freq.push(f_v[0]);
            vswr.push(f_v[1]);
          }
          //得到x轴
          this.xData = freq;
          //得到Y轴
          this.yData.push({
            name: test_name,
            type: "line",
            symbol: "none",
            smooth: true,
            data: vswr
          });
        }
      }
      //加入限制值
      let vswr = [];
      for (let i = 0; i < this.xData.length; i++) {
        vswr.push(1.7);
      }

      this.yData.push({
        name: "Reference",
        type: "line",
        symbol: "none",
        smooth: true,
        data: vswr,
        color: "red"
      });
      //将参数传入绘图
      this.$refs.chart_line_one.initChart(this.name, this.xData, this.yData);
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
}
.content {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/data/bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.title-h2 {
  color: #fff;
  padding-top: 40px;
}
.el-form .el-form-item {
  margin-top: 20%;
}
.top-link-left {
  color: #fff;
  text-align: center;
  text-align: left;
  padding-left: 10%;
  height: 40px;
}
.top-link-left li {
  float: left;
  margin-right: 2%;
  height: 40px;
  line-height: 40px;
  width: 150px;
  text-align: center;
  background: rgba(24, 39, 76, 0.8);
  border-top-left-radius: 20px;
}
.top-link-right {
  float: right;
  color: #fff;
  text-align: center;
  height: 40px;
  text-align: right;
}
.top-link-right li {
  /* float: left; */
  display: inline-block;
  width: 150px;
  text-align: center;
  line-height: 40px;
  background: rgba(24, 39, 76, 0.8);
  border-top-left-radius: 20px;
  margin-right: 20px;
}
.main-content {
  height: calc(100% - 90px);
  margin-left: 0.7%;
}

.chart-common {
  background: url("../../assets/images/data/ziyemian_bg.png") no-repeat;
  background-size: 100% 100%;
  width: 95%;
  margin: 1% auto;
  height: 100%;
  padding: 2px;
}
.chart-common h5 {
  height: 40px;
  background: url("../../assets/images/data/ziyemian_nav.png") no-repeat;
  background-size: 100% 100%;
  text-align: left;
  line-height: 40px;
  color: #fff;
  padding-left: 20px;
}
.bili-left {
  text-align: right;
  padding-right: 20px;
}
.bili-left i,
.bili-left1 i {
  font-size: 40px;
}
.bili-right {
  text-align: left;
}
.bili-left1 {
  text-align: left;
  width: 20%;
}
.bili-left1 i {
  color: #fa06b9;
}
.bili-right1 {
  text-align: left;
}
.chart-common div {
  height: calc(100% - 50px);
  margin-bottom: 1%;
}
.mapChart {
  background: url("../../assets/images/data/map_bg.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 1%;
}
.mapChart div {
  height: 100%;
}
.main-content-data {
  position: relative;
}
.data-common {
  margin: 2% auto;
  text-align: center;
}
.data-common-div {
  float: left;
  text-align: center;
}
.chart-4 {
  width: 100%;
}
.chart-2 .line_one {
  margin-left: 2%;
  margin-top: 1%;
  height: 80%;
}
.data-row {
  width: 90%;
  height: 40%;
  color: #fff;
  margin: 1% auto;
}

/* 右侧公告 */

/* 男女比例图表 */
.main-con {
  width: 100%;
}
</style>
