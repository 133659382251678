Vue.config.productionTip = false
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import global_ from './Global.js'

Vue.prototype.GLOBAL = global_;
Vue.use(ElementUI, { size: 'small', zIndex: 3000 }); //全局使用ElementUI

new Vue({
    render: h => h(App),
    router
}).$mount('#app')