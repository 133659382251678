import { request } from "./request";
import Qs from 'qs'

export function getMultidata(air_register,select_sys,select_unit) {
  return request({
    url: '/CSN/try.php',
    method: 'get',
    params: {
      register: air_register,
      system: select_sys,
      unit:select_unit
    }
  })
}

export function loginTest(username, password) {

  let parameter = Qs.stringify({
    'yanzheng': 'login_test',
    'user_name': username,
    'password': password
  });
  return request({
    url: '/CSN/try.php',
    method: 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data: parameter,
    withCredentials: false
  })
}