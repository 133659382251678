import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () =>
    import ('views/home/home.vue')
import Login from 'views/Login.vue'
import Type from 'views/SystemType.vue'
import Bigdata from 'views/bigdata/bigdata.vue'
import OnwingMonitor from 'views/bigdata/OnwingMonitor'
import Testplatform from 'views/testplatform/testplatform'

//1.安装插件
Vue.use(VueRouter);

//2.创建router
const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'productDetail',
        path: '/productDetail',
        component: () => import('views/product/ProductDetail.vue'),
    },
    {
        name: 'type',
        path: '/type',
        component: Type
    },
    {
        name: 'testplatform',
        path: '/testplatform',
        component: Testplatform
    },
    {
        name: 'bigdata',
        path: '/bigdata',
        component: Bigdata
    },
    {
        name: 'onwingmonitor',
        path: '/data/monitor',
        component: OnwingMonitor
    },

    {
        path: '/home',
        component: Home
    },

]

const router = new VueRouter({
    // scrollBehavior (to, from, savedPosition) {
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // },
    routes,
    // mode: 'history',
})

export default router
